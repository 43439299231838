import { Component, OnInit } from '@angular/core';
import { DefultPageTitleService } from 'src/app/common-services/defult-page-title.service';

@Component({
  selector: 'app-page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.css']
})
export class PageTitleComponent implements OnInit {

  PageTitles: string;
  SubPageTitles:string;

  constructor(
    private PageTitle: DefultPageTitleService
  ) {

  }

  ngOnInit(): void {
    this.PageTitle.mainHeaderTitle.subscribe(res => {
      // console.log('Page Title => ' +  res);
      this.PageTitles = res;
      });

    this.PageTitle.subHeaderTitle.subscribe(res => {
        // console.log('Page Title => ' +  res);
        this.SubPageTitles = res;
    });
  }

}
