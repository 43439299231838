import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { catchError, map } from 'rxjs/operators';
import { AuthStorageService } from './auth-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private API_URL = 'https://nmpksbank.in/apis/';
  currentUserSubject  = new BehaviorSubject<any>('null');
  redirectUrl: string;

  constructor(
    private httpClient: HttpClient,
    private dataStorage: AuthStorageService,
    private router: Router
  ) {

   }
   // This functions used for create new user
// ------------------------------------------------------------------------------------------ //
  userRegistration(updateData){
    // tslint:disable-next-line: one-variable-per-declaration
    const headers: any = new HttpHeaders({'Content-Type': 'application/json'}),
    options: any = { key : 'create',
                      fullName: updateData.value.fullName,
                      email: updateData.value.email,
                      password: updateData.value.password,
                      cPassword: updateData.value.cPassword
                    };
    const url = this.API_URL + 'manage-data.php';
    return this.httpClient.post(url, JSON.stringify(options), headers )
    .pipe(map(data => {
      return data;
      })
    );
  } // end function userRegistration
// ------------------------------------------------------------------------------------------- //
// This functions used for login user
// ------------------------------------------------------------------------------------------- //
  getFunctions(user) {
    const urlDest = this.API_URL+'login.php?email=' + user.email + '&password=' + user.password;
    return this.httpClient.get(urlDest)
    .pipe(map((dados:any) => {
          localStorage.setItem('currentUser', JSON.stringify(dados));
          sessionStorage.setItem('currentUser', JSON.stringify(dados));
          this.currentUserSubject.next(dados);
          return dados;
      })
    );
  }
  // --------------------------------------------------------------------------------------- //

// --------------------------------------------------------------------------------------- //
  signOutUser(){
    this.currentUserSubject.next('');
    this.dataStorage.userLoginData.next('');
    localStorage.removeItem('userData');
    localStorage.clear();
    sessionStorage.clear();
    this.router.navigate(['/']);
  }
// --------------------------------------------------------------------------------------- //
// -------------------------------------------------------------------------------- //
  // This functions used for add social icon data
  addSocialLinkData(socialForm: FormGroup, user: { registrationId: any; I_profile_Id?: string; registrationIdP?: string; userRole?: string; }){
    // tslint:disable-next-line: one-variable-per-declaration
    const headers: any = new HttpHeaders({'Content-Type': 'application/json'}),
    options: any = { key : 'addSocialLinkData',
                      socialLinkName: socialForm.value.socialLinkName,
                      socialLinkCss: socialForm.value.socialLinkCss,
                      socialLinkIconCss: socialForm.value.socialLinkIconCss,
                      socialLink: socialForm.value.socialLink,
                      active: socialForm.value.active,
                      registrationId: user.registrationId};
    const url = this.API_URL + 'manage-data.php';
    return this.httpClient.post(url, JSON.stringify(options), headers )
    .pipe(map(data => {
      return data;
      })
    );
  }
// -------------------------------------------------------------------------------- //
  // ------------------------------------------------ //
  // This functions used for update social icon data
  updateSocialLinkData(socialForm: FormGroup,
    user: { socialLinkName?: string;
         socialLinkCss?: string;
         socialLinkIconCss?: string;
         socialLink?: string; active?: string;
         registrationId: any; socialLinkId: any; }){
// tslint:disable-next-line: one-variable-per-declaration
const headers: any = new HttpHeaders({'Content-Type': 'application/json'}),
options: any = { key : 'updateSocialLinkData',
   socialLinkName: socialForm.value.socialLinkName,
   socialLinkCss: socialForm.value.socialLinkCss,
   socialLinkIconCss: socialForm.value.socialLinkIconCss,
   socialLink: socialForm.value.socialLink,
   active: socialForm.value.active,
   registrationId: user.registrationId,
   socialLinkId: user.socialLinkId};
const url = this.API_URL + 'manage-data.php';
return this.httpClient.post(url, JSON.stringify(options), headers )
.pipe(map(data => {
return data;
    })
  );
}
// -------------------------------------------------------------------------------- //
// ------------------------------------------------ //
// this function used for show all social link data
showAllSocialLinkInfo(user: { registrationId: any; I_profile_Id?: string; registrationIdP?: string; userRole?: string; }){
// tslint:disable-next-line: max-line-length
const urlDest = this.API_URL+'socialLinkDataShow.php?registrationId=' + user.registrationId;
return this.httpClient.get(urlDest)
.pipe(map(dados => {
return dados;
    })
  );
}
// -------------------------------------------------------------------------------- //
// ------------------------------------------------ //
// This functions used for login user
showAllSocialLinkInfoById(user: string){
//  let urlDest = "http://localhost:8888/inventory-webapi/getData.php?email=" + user.email + "&password=" + user.password;
// tslint:disable-next-line: max-line-length
const urlDest = this.API_URL+'socialLinkDataShowById.php?socialLinkId=' + user;
return this.httpClient.get(urlDest)
.pipe(map(dados => {
return dados;
})
);
}
// -------------------------------------------------------------------------------- //
// ------------------------------------------------ //
// this function used for delete social social-auth-links
deleteSocialLink(socialLinkId: any, registrationId: any){
// tslint:disable-next-line: one-variable-per-declaration
const headers: any = new HttpHeaders({'Content-Type': 'application/json'}),
options: any = { key : 'deleteSocialLink',
     registrationId,
     socialLinkId};
const url = this.API_URL + 'manage-data.php';
return this.httpClient.post(url, JSON.stringify(options), headers )
.pipe(map(data => {
return data;
})
);
}
// -------------------------------------------------------------------------------- //
// ------------------------------------------------ //
  // This functions used for show all active social links
  showAllSocialLink(){
    const urlDest = 'https://nmpksbank.in/apis/webSocialLink.php';
    return this.httpClient.get(urlDest)
    .pipe(map((dados: any) => {
      return dados;
      })
    );
  }
// ------------------------------------------------ //
// ------------------------------------------------------------------------------------------ //
feedback(updateData){
  // tslint:disable-next-line: one-variable-per-declaration
  const headers: any = new HttpHeaders({'Content-Type': 'application/json'}),
  options: any = {key : 'saveFeedBack',
                  nmpk_feedBackName: updateData.value.nmpk_feedBackName,
                  nmpk_feedBackEmail: updateData.value.nmpk_feedBackEmail,
                  nmpk_feedBackMobile: updateData.value.nmpk_feedBackMobile,
                  nmpk_feedBack: updateData.value.nmpk_feedBack
                  };
  const url = this.API_URL + 'manage-data.php';
  return this.httpClient.post(url, JSON.stringify(options), headers )
  .pipe(map(data => {
    return data;
    })
  );
} // end function userRegistration
// ------------------------------------------------------------------------------------------- //
// ------------------------------------------------------------------------------------------ //
contact(updateData){
  // tslint:disable-next-line: one-variable-per-declaration
  const headers: any = new HttpHeaders({'Content-Type': 'application/json'}),
  options: any = {key : 'contact',
                  nmpk_feedBackName: updateData.value.nmpk_feedBackName,
                  nmpk_feedBackEmail: updateData.value.nmpk_feedBackEmail,
                  nmpk_feedBackMobile: updateData.value.nmpk_feedBackMobile,
                  nmpk_feedBack: updateData.value.nmpk_feedBack
                  };
  const url = this.API_URL + 'manage-data.php';
  return this.httpClient.post(url, JSON.stringify(options), headers )
  .pipe(map(data => {
    return data;
    })
  );
} // end function userRegistration
// ------------------------------------------------------------------------------------------- //
// ------------------------------------------------ //
  // This functions used for show all active social links
  showFeedBack(){
    const urlDest = this.API_URL + 'showAllFeedBackData.php';
    return this.httpClient.get(urlDest)
    .pipe(map((dados: any) => {
      return dados;
      })
    );
  }
// ------------------------------------------------ //


// ------------------------------------------------ //
// this function used for delete social social-auth-links
deleteFeedBack(id){
  // tslint:disable-next-line: one-variable-per-declaration
  const headers: any = new HttpHeaders({'Content-Type': 'application/json'}),
            options: any = { key : 'deleteFeedBack',
                    nmpk_feedBackId:id,};
            const url = this.API_URL + 'manage-data.php';
            return this.httpClient.post(url, JSON.stringify(options), headers )
            .pipe(map(data => {
            return data;
          })
      );
  }
  // -------------------------------------------------------------------------------- //

  //- -------- this function used for upload user profile photo--------------------------------- //
  uploadPhoto(updateData,staffStatus,file: string,mimeType: string){
  // tslint:disable-next-line: one-variable-per-declaration
  const headers: any = new HttpHeaders({'Content-Type': 'application/json'}),
  fileName: any = Date.now() + '.' + mimeType,
  options: any = { key : 'saveManagementBody',
                    nmpk_staffPhoto : fileName, file,
                    token: updateData.data.token,
                    nmpk_staffName: updateData.data.nmpk_staffName,
                    nmpk_staffPost: updateData.data.nmpk_staffPost,
                    nmpk_staffBranch: updateData.data.nmpk_staffBranch,
                    nmpk_staffOfficePhone: updateData.data.nmpk_staffOfficePhone,
                    nmpk_staffResidencyPhone: updateData.data.nmpk_staffResidencyPhone,
                    nmpk_staffStatus : staffStatus.nmpk_staffStatus
                   };
  const url = this.API_URL + 'manage-data.php';
  return this.httpClient.post(url, JSON.stringify(options), headers )
  .pipe(map(data => {
  return data;
      })
    );
  } //end function uploadPhoto
// ------------------------------------------------------------------------------------------- //
// -------------------------------------------------------------------------------- //
// this function used for show single row record for login user profile
showManagementBody(){
  // tslint:disable-next-line: max-line-length
  const urlDest = this.API_URL+'managementBody.php';
  return this.httpClient.get(urlDest)
  .pipe(map(dados => {
    return dados;
    })
  );
}// end function userUpdatedData
// ------------------------------------------------------------------------------------------ //
// ------------------------------------------------ //
// this function used for delete social social-auth-links
deleteStaff(id){
  // tslint:disable-next-line: one-variable-per-declaration
  const headers: any = new HttpHeaders({'Content-Type': 'application/json'}),
            options: any = { key : 'deleteStaff',
                            nmpk_staffId:id,};
            const url = this.API_URL + 'manage-data.php';
            return this.httpClient.post(url, JSON.stringify(options), headers )
            .pipe(map(data => {
            return data;
          })
      );
  }
  // -------------------------------------------------------------------------------- //
// ------------------------------------------------ //
  showStaffById(user: string){
    const urlDest = this.API_URL+'staffDataById.php?nmpk_staffId=' + user;
    return this.httpClient.get(urlDest)
    .pipe(map(dados => {
    return dados;
      })
    );
  }
  // -------------------------------------------------------------------------------- //
  //- -------- this function used for upload user profile photo--------------------------------- //
  editStaff(updateData,data,staffStatus, oldPhoto,file: string,mimeType: string){
    // tslint:disable-next-line: one-variable-per-declaration
    const headers: any = new HttpHeaders({'Content-Type': 'application/json'}),
    fileName: any = Date.now() + '.' + mimeType,
    options: any = { key : 'updateManagementBody',
                      nmpk_staffPhoto : fileName, file,
                      oldPhoto:oldPhoto,
                      nmpk_staffId: data.nmpk_staffId,
                      nmpk_staffName: updateData.data.nmpk_staffName,
                      nmpk_staffPost: updateData.data.nmpk_staffPost,
                      nmpk_staffBranch: updateData.data.nmpk_staffBranch,
                      nmpk_staffOfficePhone: updateData.data.nmpk_staffOfficePhone,
                      nmpk_staffResidencyPhone: updateData.data.nmpk_staffResidencyPhone,
                      nmpk_staffStatus : staffStatus.nmpk_staffStatus
                    };
    const url = this.API_URL + 'manage-data.php';
    return this.httpClient.post(url, JSON.stringify(options), headers )
    .pipe(map(data => {
    return data;
        })
      );
    } //end function uploadPhoto

// -------------------------------------------------------------------------------- //
// this function used for show single row record for login user profile
showStaff(){
  // tslint:disable-next-line: max-line-length
  const urlDest = this.API_URL+'showAllStaff.php';
  return this.httpClient.get(urlDest)
  .pipe(map(dados => {
    return dados;
    })
  );
}// end function userUpdatedData
// ------------------------------------------------------------------------------------------ //

// -------------------------------------------------------------------------------- //
// this function used for show single row record for login user profile
showGallery(){
  // tslint:disable-next-line: max-line-length
  const urlDest = this.API_URL+'gallery.php';
  return this.httpClient.get(urlDest)
  .pipe(map(dados => {
    return dados;
    })
  );
}// end function userUpdatedData
////- -------- this function used for upload user profile photo--------------------------------- //
  saveGallery(updateData,staffStatus,file: string,mimeType: string){
    // tslint:disable-next-line: one-variable-per-declaration
    const headers: any = new HttpHeaders({'Content-Type': 'application/json'}),
    fileName: any = Date.now() + '.' + mimeType,
    options: any = { key : 'saveManagementBody',
                      nmpk_staffPhoto : fileName, file,
                      nmpk_staffName: updateData.data.nmpk_staffName,
                      nmpk_galleryDesc: updateData.data.nmpk_galleryDesc,
                      nmpk_staffStatus : staffStatus.nmpk_staffStatus
                     };
    const url = this.API_URL + 'manage-data.php';
    return this.httpClient.post(url, JSON.stringify(options), headers )
    .pipe(map(data => {
    return data;
        })
      );
    } //end function uploadPhoto
  // ------------------------------------------------------------------------------------------- //
  //- -------- this function used for upload user profile photo--------------------------------- //
  updateGallery(updateData,data,staffStatus, oldPhoto,file: string,mimeType: string){
    // tslint:disable-next-line: one-variable-per-declaration
    const headers: any = new HttpHeaders({'Content-Type': 'application/json'}),
    fileName: any = Date.now() + '.' + mimeType,
    options: any = { key : 'updateManagementBody',
                      nmpk_staffPhoto : fileName, file,
                      oldPhoto:oldPhoto,
                      nmpk_staffId: data.nmpk_staffId,
                      nmpk_staffStatus : staffStatus.nmpk_staffStatus,
                      nmpk_staffName: updateData.data.nmpk_staffName,
                      nmpk_galleryDesc: updateData.data.nmpk_galleryDesc,
                    };
    const url = this.API_URL + 'manage-data.php';
    return this.httpClient.post(url, JSON.stringify(options), headers )
    .pipe(map(data => {
    return data;
        })
      );
    } //end function uploadPhoto

// -------------------------------------------------------------------------------- //

// -------------------------------------------------------------------------------- //
// this function used for show single row record for login user profile
showAnnualReports(){
  // tslint:disable-next-line: max-line-length
  const urlDest = this.API_URL+'showAllAnnualReports.php';
  return this.httpClient.get(urlDest)
  .pipe(map(dados => {
    return dados;
    })
  );
}// end function userUpdatedData
// ------------------------------------------------------------------------------------------ //
// ------------------------------------------------------------------------------------------ //
saveSuggestion(updateData){
  // tslint:disable-next-line: one-variable-per-declaration
  const headers: any = new HttpHeaders({'Content-Type': 'application/json'}),
  options: any = {key : 'saveSuggestion',
                  nmpk_suggestionTitle: updateData.nmpk_suggestionTitle,
                  nmpk_suggestionStatus: updateData.nmpk_suggestionStatus,
                  nmpk_suggestionDesc: updateData.nmpk_suggestionDesc,
                  nmpk_suggestionId :updateData.nmpk_suggestionId
                  };
  const url = this.API_URL + 'manage-data.php';
  return this.httpClient.post(url, JSON.stringify(options), headers )
  .pipe(map(data => {
    return data;
    })
  );
} // end function
// ------------------------------------------------------------------------------------------- //
// ------------------------------------------------ //
// this function used for delete social social-auth-links
deleteSuggestions(id){
  // tslint:disable-next-line: one-variable-per-declaration
  const headers: any = new HttpHeaders({'Content-Type': 'application/json'}),
            options: any = { key : 'deleteSuggestions',
                            nmpk_suggestionId:id,};
            const url = this.API_URL + 'manage-data.php';
            return this.httpClient.post(url, JSON.stringify(options), headers )
            .pipe(map(data => {
            return data;
          })
      );
  }
  // -------------------------------------------------------------------------------- //
  showSuggestionsById(id){
    const urlDest = this.API_URL+'showSuggestionById.php?nmpk_suggestionId=' + id;
    return this.httpClient.get(urlDest)
    .pipe(map(dados => {
    return dados;
      })
    );
  }
  // -------------------------------------------------------------------------------- //
  showSuggestions(){
    const urlDest = this.API_URL+'showAllSuggestions.php';
    return this.httpClient.get(urlDest)
    .pipe(map(dados => {
    return dados;
      })
    );
  }
// ------------------------------------------------------------------------------------------ //
saveNews(updateData,file: string,mimeType: string){
  // tslint:disable-next-line: one-variable-per-declaration
  const headers: any = new HttpHeaders({'Content-Type': 'application/json'}),
  fileName: any = Date.now() + '.' + mimeType,                
  options: any = {key : 'saveNews',
                  nmpk_newsUpload : fileName, file,
                  nmpk_newsTitle: updateData.nmpk_newsTitle,
                  nmpk_newsStatus: updateData.nmpk_newsStatus,
                  nmpk_newsDesc: updateData.nmpk_newsDesc,
                  nmpk_newsId :updateData.nmpk_newsId
                  };
  const url = this.API_URL + 'manage-data.php';
  return this.httpClient.post(url, JSON.stringify(options), headers )
  .pipe(map(data => {
    return data;
    })
  );
} // end function
// ------------------------------------------------------------------------------------------ //
deleteNews(id){
  // tslint:disable-next-line: one-variable-per-declaration
  const headers: any = new HttpHeaders({'Content-Type': 'application/json'}),
            options: any = { key : 'deleteNews',
                            nmpk_newsId:id,};
            const url = this.API_URL + 'manage-data.php';
            return this.httpClient.post(url, JSON.stringify(options), headers )
            .pipe(map(data => {
            return data;
          })
      );
  }
  // -------------------------------------------------------------------------------- //
  // -------------------------------------------------------------------------------- //
  showNewsById(id){
    const urlDest = this.API_URL+'showNewsById.php?nmpk_newsId=' + id;
    return this.httpClient.get(urlDest)
    .pipe(map(dados => {
    return dados;
      })
    );
  }
  // -------------------------------------------------------------------------------- //
  showNews(){
    const urlDest = this.API_URL+'showAllNews.php';
    return this.httpClient.get(urlDest)
    .pipe(map(dados => {
    return dados;
      })
    );
  }
// ------------------------------------------------------------------------------------------ //



}

