import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class DefultPageTitleService {
  mainHeaderTitle = new BehaviorSubject('');
  subHeaderTitle = new BehaviorSubject('');
  ShowHomePageTitle = new BehaviorSubject(false);
  loginUserPhoto = new BehaviorSubject('User photo');

  companyLogo = new BehaviorSubject('');

  constructor() { }
}
