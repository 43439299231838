import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedRoutingModule } from './shared-routing.module';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { BackendFooterComponent } from './backend-footer/backend-footer.component';
import { BackendHeaderComponent } from './backend-header/backend-header.component';
import { PageTitleComponent } from './page-title/page-title.component';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { DefultNoteComponent } from './defult-note/defult-note.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AlertComponent } from './alert/alert.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { NgxPaginationModule } from 'ngx-pagination';
import { OrderModule } from 'ngx-order-pipe';
import { NewsMarqueeComponent } from './news-marquee/news-marquee.component';
import { CarouselModule } from 'ngx-bootstrap/carousel';

const data =[
  HeaderComponent,
  FooterComponent,
  BackendFooterComponent,
  BackendHeaderComponent,
  PageTitleComponent,
]

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    BackendFooterComponent,
    BackendHeaderComponent,
    PageTitleComponent,
    SideMenuComponent,
    DefultNoteComponent,
    AlertComponent,
    NewsMarqueeComponent,
  ],
  imports: [
    CommonModule,
    SharedRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    Ng2SearchPipeModule,
    NgxPaginationModule,
    FormsModule,
    OrderModule,
    CarouselModule
  ],
  exports:[
    HeaderComponent,
    FooterComponent,
    BackendFooterComponent,
    BackendHeaderComponent,
    PageTitleComponent,
    SideMenuComponent,
    DefultNoteComponent,
    ReactiveFormsModule,
    HttpClientModule,
    AlertComponent,
    Ng2SearchPipeModule,
    NgxPaginationModule,
    FormsModule,
    OrderModule,
    NewsMarqueeComponent,
    CarouselModule

  ],
})
export class SharedModule { }
