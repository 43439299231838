import { Component, OnInit } from '@angular/core';
import { AlertService } from 'src/app/common-services/alert.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.css']
})
export class AlertComponent implements OnInit {

  error = '';
  success = '';
  info = '';
  status = '';
  heading = '';
  // private subscription: Subscription;
  // message: any;

  options = {
    autoClose: false,
    keepAfterRouteChange: false
  };

  constructor(
    private alertService: AlertService
    ) {
   }

  ngOnInit() {
    this.alertService.info.subscribe(res => {
      this.info = res;
      this.myFunction();
    });

    this.alertService.success.subscribe(res => {
      this.success = res;
      this.myFunction();
    });

    this.alertService.error.subscribe(res => {
      this.error = res;
      this.myFunction();
    });

    this.alertService.heading.subscribe(res => {
      this.heading = res;
      this.myFunction();
    });

    this.alertService.info.subscribe(res => {
      this.info = res;
      this.myFunction();
    });

  }

  myFunction() {
  setTimeout(this.alertFunc, 3000);
  }

  alertFunc() {
    $(document).ready( function() {
      $('.alert').fadeOut(3000);
    });
  }

}
