<!-- Footer -->
<!-- <app-defult-note></app-defult-note> -->
<footer id="site-footer" class="box-custom-border">
    <div class="wrapper">
        <!-- Footer Top -->
        <div class="footer-top">
            <div class="row margin-none">
                <!-- Footer Top About -->
                <div class="col-md-4 col-sm-12 padding-none clearfix">
                    <img src="assets/img/logo-red.png" class="align-middle" alt="nmpksbank">
                </div>    
                <div class="col-md-3 col-sm-12 padding-none clearfix">
                    <img src="assets/img/dicgc.png" class="align-middle" alt="nmpksbank">
                </div>
                <!-- Footer Top About End -->

                <!-- Footer Top Menu -->
                <div class="col-md-5 col-sm-12 padding-none clearfix">
                    <nav class="top-menu clearfix">
                        <ul class="nav-default pull-right clearfix">
                            <li routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"><a routerLink="/">मुख्य पुष्ठ</a></li>
                            <li routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"><a routerLink="/about">आमच्या संबंधित माहिती</a></li>
                            <li routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"><a routerLink="/about/news">बातमी</a></li>
                            <li routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"><a routerLink="/suggestion-complaint">तक्रार नोंदवा</a></li>
                            <!-- <li><a href="team-list-1.html">TEAM</a></li>
                            <li><a href="blog-list-masonry-4-col.html">BLOG</a></li> -->
                            <li routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"><a routerLink="/contact">संपर्क</a></li>
                            <li><a href="https://nmpksbank.in/privacy_policy.html" target="_blank">Privacy Policy</a></li>
                        </ul>
                    </nav>
                    <!-- Footer Top Menu End -->

                    <!-- Footer Top Newsletter -->
                    <!-- <div class="top-newsletter pull-right">
                        <form>
                            <input type="text" name="newsletter" placeholder="Please enter your email address">
                            <button type="button"><i class="fa fa-paper-plane"></i></button>
                        </form>
                    </div> -->
                    <!-- Footer Top Newsletter End -->
                </div>
            </div>
        </div>
        <!-- Footer Top End -->

        <!-- Footer Middle -->
        <div class="footer-middle">
            <div class="row margin-none">
                <!-- Footer Middle Address -->
                <div class="col-md-8 col-sm-12 padding-none">
                    <ul class="address-list nav-default text-small clearfix">
                        <!-- <li><i class="fa fa-map-marker"></i>2st Floor Road London SE1 7AA</li> -->
                        <li><i class="fa fa-phone"></i>(0712) - 2721225, 2721832</li>
                        <!-- <li><i class="fa fa-mobile-phone"></i>+20 7435 0228</li> -->
                        <li><i class="fa fa-send"></i><a href="mailto:info@nmpksbank.in">info@nmpksbank.in</a></li>
                    </ul>
                </div>
                <!-- Footer Middle Address End -->

                <!-- Footer Middle Social -->
                <div class="col-md-4 col-sm-12 padding-none">
                    <ul class="social-icons nav-default pull-right clearfix">
                        <li *ngFor="let item of allSocialData">
                            <a href="https://{{item.socialLink}}" target="_blank" class="{{item.socialLinkCss}}">
                                <i class="{{item.socialLinkIconCss}}"></i>
                            </a>
                        </li>
                        <!-- <li><a href="#" class="facebook"><i class="fa fa-facebook"></i></a></li>
                        <li><a href="#" class="twitter"><i class="fa fa-twitter"></i></a></li>
                        <li><a href="#" class="google"><i class="fa fa-google-plus"></i></a></li>
                        <li><a href="#" class="linkedin"><i class="fa fa-linkedin"></i></a></li> -->
                    </ul>
                </div>
                <!-- Footer Middle Social End -->
            </div>
        </div>
        <!-- Footer Middle End -->

        <!-- Footer Bottom -->
        <div class="footer-bottom">
            <div class="row margin-none">
                <div class="col-md-6 col-sm-12 padding-none">
                    <p class="text-medium text-small">© 2023-24 नागपूर महानगरपालिका कर्मचारी सहकारी बँक लि. | सर्व हक्क राखीव.</p>
                </div>
                <div class="col-md-6 col-sm-12 padding-none ">
                    <p class="text-medium text-small pull-right">
                        Design and Development by
                        <a href="https://syntecks.com" class="" target="_blank">Syntecks Infotech.</a>
                    </p>
                </div>
            </div>
        </div>
        <!-- Footer Bottom End -->
    </div>
</footer>
<!-- Footer End -->
