<div class="sticky-top">
    <nav class="navbar navbar-expand-lg ftco_navbar ftco-navbar-light sticky-top box-custom-border-10" id="ftco-navbar">
        <div class="container">
            <a class="navbar-brand" href="index.html">
                <img src="assets/img/logo-red.png" class="align-middle" alt="nmpksbank">
            </a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#main_nav" aria-controls="ftco-nav" aria-expanded="false" aria-label="Toggle navigation">
                <span class="fa fa-bars"></span> Menu
            </button>
            <div *ngIf="userData !=''">
                <div class="collapse navbar-collapse" id="main_nav">
                    <ul class="navbar-nav ms-auto">
                        <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
                            <a class="nav-link" routerLink="/backend/dashboard"> Social links</a>
                        </li>
                        <li class="nav-item dropdown" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
                            <a class="nav-link dropdown-toggle" id="dropdown04" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                About
                        </a>
                            <div class="dropdown-menu" aria-labelledby="dropdown04">
                                <a class="dropdown-item" routerLink="/backend/about">Management Body</a>
                                <a class="dropdown-item" routerLink="/backend/about/staff">Office Staff</a>
                                <a class="dropdown-item" routerLink="/backend/about/reports">Report</a>
                            </div>
                        </li>

                        <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
                            <a class="nav-link" routerLink="/backend/feedback">
                        Feedback
                      </a>
                        </li>
                        <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
                            <a class="nav-link" routerLink="/backend/gallery">Gallery</a>
                        </li>
                        <li class=" nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
                            <a class="nav-link" routerLink="/backend/news">News</a>
                        </li>
                        <li class=" nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
                            <a class="nav-link" routerLink="/backend/suggestion">Suggestion</a>
                        </li>

                        <li class="nav-item dropdown">
                            <!-- Menu Toggle Button -->
                            <a class="nav-link dropdown-toggle" data-toggle="dropdown">
                                <span class="hidden-xs">{{userData.fullName}}</span>
                            </a>
                            <div class="dropdown-menu" aria-labelledby="dropdown04">
                                <a (click)="logout()" class=" dropdown-item line-bottom left">Sign out</a>
                                <!-- <a class="dropdown-item" href="#">सोने तारण कर्ज</a>
                        <a class="dropdown-item" href="#">Page 4</a> -->
                            </div>
                        </li>
                    </ul>
                </div>

            </div>
        </div>
    </nav>
</div>
