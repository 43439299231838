<header id="site-footer">
    <div class="container">
        <!-- Footer Middle -->
        <div class="footer-middle">
            <div class="row margin-none">
                <!-- Footer Middle Address -->
                <div class="col-md-8 col-sm-12 padding-none">
                    <ul class="address-list nav-default text-small clearfix">
                        <!-- <li><i class="fa fa-map-marker"></i>2st Floor Road London SE1 7AA</li> -->
                        <li><i class="fa fa-phone"></i>(0712) - 2721225, 2721832</li>
                        <!-- <li><i class="fa fa-mobile-phone"></i>+20 7435 0228</li> -->
                        <li><i class="fa fa-send"></i><a href="mailto:info@nmpksbank.in">info@nmpksbank.in</a></li>
                        <li>
                            <b>GST NO. - 27AAAANO175E1ZX</b>
                        </li>
                        <li>
                            <b>PAN NO. - AAAANO175E</b>
                        </li>
                    </ul>
                </div>
                <!-- Footer Middle Address End -->

                <!-- Footer Middle Social -->
                <div class="col-md-4 col-sm-12 padding-none">
                    <ul class="social-icons nav-default pull-right clearfix">
                        <li *ngFor="let item of allSocialData">
                            <a href="https://{{item.socialLink}}" target="_blank" class="{{item.socialLinkCss}}">
                                <i class="{{item.socialLinkIconCss}}"></i>
                            </a>
                        </li>
                        <!-- <li><a href="#" class="twitter"><i class="fa fa-twitter"></i></a></li>
                        <li><a href="#" class="google"><i class="fa fa-google-plus"></i></a></li>
                        <li><a href="#" class="linkedin"><i class="fa fa-linkedin"></i></a></li> -->
                    </ul>

                    <ul class="social-icons nav-default pull-right clearfix">
                        <li routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
                            <a routerLink="/backend/auth" class="linkedin" title="login" alt="login">
                                <i class="fa fa-sign-in"></i>
                            </a>
                        </li>
                        &nbsp; &nbsp;
                    </ul>
                </div>
                <!-- Footer Middle Social End -->
            </div>
        </div>
        <!-- Footer Middle End -->
    </div>
</header>
<div class="sticky-top">
    <nav class="navbar navbar-expand-lg ftco_navbar ftco-navbar-light sticky-top box-custom-border-10" id="ftco-navbar ">
        <div class="container">
            <a class="navbar-brand" href="index.html">
                <img src="assets/img/logo-red.png" class="align-middle" alt="nmpksbank">
                <!-- &nbsp; नागपूर महानगरपालिका  <span><br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;कर्मचारी सहकारी बँक लि</span> -->
            </a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#main_nav" aria-controls="ftco-nav" aria-expanded="false" aria-label="Toggle navigation">
                <span class="fa fa-bars"></span> Menu
              </button>
            <div class="collapse navbar-collapse" id="main_nav">
                <ul class="navbar-nav ms-auto">
                    <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
                        <a class="nav-link" routerLink="/"> मुख्य पुष्ठ</a>

                        <!-- <a class="nav-link" href="#">
                    मुख्य पुष्ठ
                </a> -->
                    </li>
                    <li class="nav-item dropdown" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
                        <a class="nav-link dropdown-toggle" id="dropdown04" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      आमच्या संबंधित माहिती
                </a>
                        <div class="dropdown-menu" aria-labelledby="dropdown04">
                            <a class="dropdown-item" routerLink="/about">संस्थेची माहिती</a>
                            <a class="dropdown-item" routerLink="/about/management">संचालक मंडळ</a>
                            <a class="dropdown-item" routerLink="/about/staff">अधिकारी वर्ग</a>
                            <a class="dropdown-item" routerLink="/about/branches">शाखा</a>
                            <a class="dropdown-item" routerLink="/about/news">बातमी</a>
                            <a class="dropdown-item" routerLink="/about/annual-reports">वार्षिक अहवाल</a>
                            <a class="dropdown-item" routerLink="/about/gallery">गॅलरी</a>
                        </div>
                    </li>
                    <li class="nav-item dropdown" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
                        <a class="nav-link dropdown-toggle" id="dropdown04" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                सेवा
              </a>
                        <div class="dropdown-menu" aria-labelledby="dropdown04">
                            <a class="dropdown-item" routerLink="/services/deposit">ठेवी</a>
                            <a class="dropdown-item" routerLink="/services/loan">कर्ज</a>
                            <a class="dropdown-item" href="javascript:void(0)" [routerLink]="['/services/atm']">ATM</a>
                            <a class="dropdown-item" href="javascript:void(0)">RTGS / NEFT</a>
                            <a class="dropdown-item" href="javascript:void(0)">डिमांड ड्रॅफ्ट</a>
                            <!-- <a class="dropdown-item" href="#">सोने तारण कर्ज</a>
                        <a class="dropdown-item" href="#">Page 4</a> -->
                        </div>
                    </li>

                    <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
                        <a class="nav-link" routerLink="/suggestion-complaint">सूचना</a>
                    </li>
                    <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
                        <a class="nav-link" routerLink="/feedback">अभिप्राय / तक्रार नोंदवा</a>
                    </li>
                    <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
                        <a class="nav-link" routerLink="/contact">संपर्क</a>
                    </li>

                    <!-- <li class="nav-item">
                    <a class="nav-link" href="#">
                    संपर्क
                </a>
                </li> -->
                </ul>

            </div>

        </div>
    </nav>
</div>