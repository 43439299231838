<div class="box-white padding-all-20">
    <!--Recents -->
    <aside class="blog-recents">
        <h4 class="line-bottom left">महत्वपूर्वक लिंक</h4>
        <ul>
            <li><a href="javascript:void(0)" routerLink="/about/news">संपूर्ण बातमी</a></li>
            <li><a href="javascript:void(0)" routerLink="/about/management">संचालक मंडळ</a></li>
            <li><a href="javascript:void(0)" routerLink="/about/annual-reports">सहकारी वर्षांचा अहवाल</a></li>
            <li><a href="javascript:void(0)" routerLink="/about">संस्थेची माहिती</a></li>
            <li><a href="javascript:void(0)" routerLink="/about/gallery">गॅलरी</a></li>
            <li><a href="javascript:void(0)" routerLink="/about/branches">Bank IFSC Code</a></li>
            <li><a href="https://play.google.com/store/apps/details?id=com.vgipl.nmksb" target="_blank">Download NMPKS Bank Viewing app</a></li>
        </ul>
    </aside>
    <aside class="blog-recents">
        <h4 class="line-bottom left">सूचना आणि बातमी</h4>
        <ul>
            <li *ngFor="let item of dataNews"><a href="javascript:void(0)" routerLink="/about/news">{{item.nmpk_newsTitle}}</a></li>
        </ul>
    </aside>
    <!-- Blog Recents End -->
</div>