import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/common-services/auth.service';
import {formatDate} from '@angular/common';
@Component({
  selector: 'app-news-marquee',
  templateUrl: './news-marquee.component.html',
  styleUrls: ['./news-marquee.component.css']
})
export class NewsMarqueeComponent implements OnInit {

  dataNews: any;
  noWrapSlides = false;
  showIndicator = false;
  today;
  constructor(
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.today = Date.now();
    this.showNews();
  }

  showNews(){
    this.authService.showNews()
    .subscribe((res: any) => {
      this.dataNews = res;
      // console.log(res);
    });
  }

}
