<div class="row margin-none col-half">
    <!-- Clients -->
    <div class="col-md-4 col-sm-12 half box-yellow padding-all-5 center">
        <!-- Section Header -->
        <div class="container">
            <h4 class="title-big center">उपयुक्त बातमी</h4>
        </div>
    </div>
    <div class="col-md-8 col-sm-12 half box-dark center padding-all-10">
        <marquee onmouseover="this.stop();" onmouseout="this.start();" direction="left" behavior="scroll" scrolldelay="2" speed="slow" scrollamount="2">
            तुम्हाला निरोगी आणि समृद्ध नवीन वर्ष {{today | date:'yyyy'}} च्या खूप खूप शुभेच्छा. - - - खबरदारी: कधीही तुमचा कार्ड नंबर, एक्स्पायरी डेट, सीव्हीव्ही, पिन, ओटीपी, पासवर्ड कोणाशीही शेअर करू नका. बँक असे तपशील कधीच विचारत नाही. &nbsp;&nbsp;&nbsp;
            <span *ngFor="let user of dataNews">
                <b class="text-extrabold">
                    <a href="javascript:void(0)" style="color:#FFF;" routerLink="/about/news">{{user.nmpk_newsTitle}} -</a></b> &nbsp;&nbsp;&nbsp; {{user.nmpk_newsDesc.slice(0,50)}}...&nbsp;&nbsp;&nbsp;
            </span>
        </marquee>
    </div>
</div>
<!-- Codes by HTMLcodes.ws -->
<!-- <marquee behavior="scroll" direction="right" onmouseover="this.stop();" onmouseout="this.start();">
  <span *ngFor="let user of dataNews">
    <b class="">{{user.nmpk_newsTitle}} -</b> &nbsp;&nbsp;&nbsp; {{user.nmpk_newsDesc.slice(0,50)}}...&nbsp;&nbsp;&nbsp;
   </span>
</marquee> -->
