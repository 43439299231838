import { Component, OnInit } from '@angular/core';
import { AuthStorageService } from 'src/app/common-services/auth-storage.service';
import { AuthService } from 'src/app/common-services/auth.service';

@Component({
  selector: 'app-backend-header',
  templateUrl: './backend-header.component.html',
  styleUrls: ['./backend-header.component.css']
})
export class BackendHeaderComponent implements OnInit {
  userData: any;

  constructor(
    private dataStorage: AuthStorageService,
    private authService :AuthService,
  ) {

   }

  ngOnInit(): void {
    this.dataStorage.userLoginData
    .subscribe(data =>{
        if(data){
          this.userData = data;
        }else{
          this.userData = '';
        }
      });
    }

    logout(){
      this.authService.signOutUser();
    }

}
