<div *ngIf="error" role="alert" class="alert alert-danger alert-dismissible callout callout-danger">
    <h4><i class="icon fa fa-ban"></i> {{heading}}</h4>
    {{error}}
</div>

<!-- <div class="alert alert-info alert-dismissible callout callout-info" role="alert" data-auto-dismiss="2000">
    <h4><i class="icon fa fa-info"></i> Alert!</h4>
    Info alert preview. This alert is dismissable.
</div> -->
<div *ngIf="info" id="info" data-auto-dismiss="2000" role="alert" class="alert alert-warning alert-dismissible">
    <h4><i class="icon fa fa-warning"></i> {{heading}}</h4>
    {{info}}
</div>
<div *ngIf="success" role="alert" class="alert alert-success alert-dismissible callout callout-success">
    <h4><i class="icon fa fa-check"></i> {{heading}}</h4>
    {{success}}
</div>