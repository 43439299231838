import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  info = new BehaviorSubject('');
  error = new BehaviorSubject('');
  success = new BehaviorSubject('');
  heading = new BehaviorSubject('');

  updateType = new BehaviorSubject('');


    constructor(private router: Router) {
     // this.info.subscribe(res => {
     //   this.info.next(res);
      //  console.log('hello=>' + res);
    //  });
     //  console.log('hello=>' + this.info);
   }


    clear() {
        // clear by calling subject.next() without parameters
        this.info.next('');
        this.error.next('');
        this.success.next('');
        this.heading.next('');
        this.updateType.next('');
    }
}
