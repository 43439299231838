import { BackendFeedBackModule } from './../backend-feed-back/backend-feed-back.module';
import { AuthGuard } from './../common-services/auth.guard';
import { ServicesModule } from './../services/services.module';
import { Routes } from '@angular/router';

export const BACKEND_ROUTES: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('../auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'dashboard',
    canActivate:[AuthGuard],
    loadChildren: () => import('../dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'services',
    canActivate:[AuthGuard],
    loadChildren: () => import('../services/services.module').then(m => m.ServicesModule)
  },
  {
    path: 'about',
    canActivate:[AuthGuard],
    loadChildren: () => import('../backend-about/backend-about.module').then(m => m.BackendAboutModule)
  },
  {
    path: 'feedback',
    canActivate:[AuthGuard],
    loadChildren: () => import('../backend-feed-back/backend-feed-back.module').then(m => m.BackendFeedBackModule)
  },
  {
    path: 'gallery',
    canActivate:[AuthGuard],
    loadChildren: () => import('../gallery/gallery.module').then(m => m.GalleryModule)
  },
  {
    path: 'suggestion',
    canActivate:[AuthGuard],
    loadChildren: () => import('../suggestion/suggestion.module').then(m => m.SuggestionModule)
  },  {
    path: 'news',
    canActivate:[AuthGuard],
    loadChildren: () => import('../backend-news/backend-news.module').then(m => m.BackendNewsModule)
  },



];
