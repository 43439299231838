import { logging } from 'protractor';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable({
  providedIn: 'root'
})
export class AuthStorageService {

  userLoginData  = new BehaviorSubject('');

  employeeData = new BehaviorSubject({
    hrms_registrationToken:'',
    hrms_businessId:'',
    hrms_empToken:'',
    hrms_empName:'',
    hrms_empMiddleName:'',
    hrms_empLastName:'',
    hrms_empJoiningDate:'',
    hrms_empDateOfBirth:'',
    hrms_empConfirmationDate:'',
    hrms_empGender:'',
    hrms_empOfficePhone:'',
    hrms_empMobileNumber:'',
    hrms_empResidencePhone:'',
    hrms_empEmergencyContact:'',
    hrms_empEmployeeCode:'',
    hrms_empAttendanceCode:'',
    hrms_empActiveOrNot:'',
    hrms_empApproved:'',
    hrms_empAddDate:'',
    hrms_empStatus:'',
    hrms_empId:'',
  });

    constructor(

    ) {

      }

  private _loginInfo: any;

  get loginInfo(): any{
      return this._loginInfo;
  }
    set loginInfo(value){
        this._loginInfo = value;
        this.userLoginData.next(this.loginInfo);
        // console.log(this.loginInfo);
  }


  private _empInfo: any;

  get empInfo(): any{
      return this._empInfo;
  }
    set empInfo(value){
        this._empInfo = value;
      //  console.log(this.empInfo);
        this.employeeData.next(this.empInfo);

  }


  private _statesInfo: any;

  get stateInfo(): any{
      return this._statesInfo;
  }
    set stateInfo(value){
        this._statesInfo = value;
        // console.log(this.stateInfo);

  }




}
