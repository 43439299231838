import { Component, OnInit } from '@angular/core';
import { AuthStorageService } from 'src/app/common-services/auth-storage.service';
import { AuthService } from 'src/app/common-services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  allSocialData: any;
  dataAvailable: any;

  constructor(
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.getAllSocialList();
  }

  getAllSocialList(){
    this.authService.showAllSocialLink()
    .subscribe( res => {
      if(res){
        this.allSocialData = res;
      }else{
        this.allSocialData = '';
      }
      // console.log(this.allSocialData);
    });
  }
}
