import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-defult-note',
  templateUrl: './defult-note.component.html',
  styleUrls: ['./defult-note.component.css']
})
export class DefultNoteComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
