import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/common-services/auth.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  allSocialData: any;

  constructor(
    private authService: AuthService

  ) { }

  ngOnInit(): void {
    this.getAllSocialList();

  }

  getAllSocialList(){
    this.authService.showAllSocialLink()
    .subscribe( res => {
      if(res){
        this.allSocialData = res;
      }else{
        this.allSocialData = '';
      }
      // console.log(this.allSocialData);
    });
  }
}
