<!-- Section Banner -->
<div class="box-dark-light">
    <div class="wrapper padding-all">
        <div class="section-header banner">
            <div class="row">
                <div class="col-md-8 col-sm-12">
                    <h2 class="line-bottom left">Download the App on</h2>
                    <div class="box-font-color">
                        <img src="assets/img/vpay_logo.png" class="align-middle" alt="nmpksbank">
                    </div>
                    <!-- <a href="#" class="btn btn-default v-center">VIEW SERVICE PLANS</a> -->
                </div>
                <div class="col-md-4 col-sm-12">
                    <a href="#" class="">
                        <img src="assets/img/appstore.png" class="align-middle" alt="nmpksbank">
                    </a>
                    <div class="space"></div>
                    <a href="#" class="">
                        <img src="assets/img/playstore.png" class="align-middle" alt="nmpksbank">
                    </a>
                </div>
            </div>

        </div>
    </div>
</div>
<!-- Section Banner End -->