import { AuthService } from 'src/app/common-services/auth.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  abc = false;

  constructor(
    private router: Router,
    private authService: AuthService
  ) {

   }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree  {
      return this.authService.currentUserSubject.pipe(
        take(1),
        map((user:any) => {
          if(user.dados){
            return true;
          }
          return this.router.createUrlTree(['home']);
        })
      )
    }

}
