import { Component, OnInit } from '@angular/core';
import { DefultPageTitleService } from 'src/app/common-services/defult-page-title.service';

@Component({
  selector: 'app-default-layout',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.css']
})
export class DefaultLayoutComponent implements OnInit {
  PageTitles;
  HomePageTitles;
  constructor(
    private PageTitle: DefultPageTitleService
    )
  {
  }

  ngOnInit(): void {
    this.PageTitle.mainHeaderTitle.subscribe(res => {
      // console.log('Page Title => ' +  res);
      this.PageTitles = res;
      });
    this.PageTitle.ShowHomePageTitle.subscribe(res => {
      // console.log('Page Title => ' +  res);
      this.HomePageTitles = res;
      });
  }


}
