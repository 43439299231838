<footer id="site-footer" class="box-custom-border">
    <div class="wrapper">
        <div class="footer-bottom">
            <div class="row margin-none">
                <div class="col-md-6 col-sm-12 padding-none">
                    <p class="text-medium text-small">© 2024 नागपूर महानगरपालिका कर्मचारी सहकारी बँक लि. | सर्व हक्क राखीव.</p>
                </div>
                <div class="col-md-6 col-sm-12 padding-none ">
                    <p class="text-medium text-small pull-right">
                        Design and Development by
                        <a href="https://syntecks.com" class="" target="_blank">Syntecks Infotech.</a>
                    </p>
                </div>
            </div>
        </div>
    </div>
</footer>