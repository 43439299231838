import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/common-services/auth.service';
import { map, take } from 'rxjs/operators';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.css']
})
export class SideMenuComponent implements OnInit {
  dataNews: any;
  constructor(
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.showNews();
  }

  showNews(){
    this.authService.showNews()
    .pipe(
      take(5))
      .subscribe((res: any) => {
        this.dataNews = res;
      }
    );
  }


}
